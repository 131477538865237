<template>
  <div class="TipsNews">
    <top-bar title="收费标准" hasBack></top-bar>
    <div class="content">
      测试内容
    </div>

  </div>
</template>

<script>
  import TopBar from '@/components/TopBar';
  import { GoodsMixin } from '@/mixins/goodsMixin';
  import ajax from '@/api';
  export default {
    name: 'WaterQuality',
    mixins: [ GoodsMixin ],
    inject: [ 'reload' ],
    components: { TopBar },
    data() {
      return {

      }
    },
    watch: {

    },
    created() {

    },
    methods: {

    }
  }

</script>

<style lang="scss" scoped>
  @import "./style.scss";
</style>