<template>
  <van-nav-bar :fixed="fixed">
    <template #title>
      <span :style="{ color: color }">{{title}}</span>
    </template>
    <template #left>
      <van-icon class="back-icon" name="arrow-left" size="18px" :color="color" @click="goBack" />
    </template>
  </van-nav-bar>
</template>

<script>
  export default {
    name: 'TopBar',
    props: {
      color: { type: String, default: '#333' },
      fixed: { type: Boolean, default: false },
      title: { type: String, default: '' },
      hasClose: { type: Boolean, default: false },
      outBrowse: { type: Boolean, default: false },
      outOrder: { type: Boolean, default: false },
      outAddressEdit: { type: Boolean, default: false },
    },
    methods: {
      goBack() {
        if (this.hasClose) {
          this.$emit('close', false);
          return;
        }

        if (this.outOrder) {
          // 退出订单支付页
          this.$emit('outOrderEvt');
          return;
        }

        if (this.outAddressEdit) {
          // 退出地址编辑页
          this.$emit('outAddressEditEvt');
          return;
        }

        this.outBrowse
          ? this.$router.push({ name: 'Me' })
          : this.$router.back();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-bar {
    display: flex;
    align-items: center;
    height: 11.5vw;
    padding: 0 3vw;
    background: #fff;
    border-bottom: 1px solid #eee;
    font-size: 4.5vw;
    box-sizing: border-box;
  }
  .center { justify-content: center; }
  .between { justify-content: space-between; }
</style>